body,
html {
  margin: 0;
  height: 100%;
  background-color: #1a1d22;
}

.App {
  position: relative;
}

.App-logo {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 200px;
  max-width: 100%;
  height: auto;
  cursor: pointer;
}

.modal-open {
  background-color: rgba(26, 29, 34, 0.8);
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  background-color: white;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  overflow: hidden;
}

.modal-content {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modal-content input {
  width: 80%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.modal-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 20px;
  box-sizing: border-box;
  border-top: 1px solid #ccc;
}

.modal-footer .finish-later,
.modal-footer .next-step,
.modal-footer .finish {
  color: #1a1d22;
  cursor: pointer;
}

.modal-footer .next-step {
  font-weight: bold;
}

.modal-footer .finish {
  font-weight: bold;
}

.step-2 {
  display: flex;
  height: 100%;
}

.step-2-left {
  width: 40%;
  height: 100%;
}

.stack-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px 0 0 15px;
}

.step-2-right {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* Two columns */
  grid-template-rows: repeat(2, 1fr);
  /* Two rows */
  gap: 10px;
  /* 10px spacing between all items */
  width: 100%;
  height: calc(100% - 20px);
  /* Account for spacing and ensure grid fits inside modal */
  padding: 10px;
  box-sizing: border-box;
}

.grid-item {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  height: 100%;
  /* Ensure each grid item takes full height of the grid cell */
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.grid-item:hover {
  background-color: #e0e0e0;
  border-color: #555;
}

.grid-item.clicked {
  background-color: #d0d0d0;
  border-color: #333;
}